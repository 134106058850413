import * as React from "react";
import laser from "../assets/laser.webp";
import nails from "../assets/nails.webp";
import waxing from "../assets/waxing.webp";
import wimpern from "../assets/wimpern.webp";
import behandlung from "../assets/gesichtsbehandlung1.webp";

function ServiceCard({ imgSrc, title, description, imgAlt }) {
  return (
    <article>
      <div className="overflow-hidden rounded-2xl">
        <img
          loading="lazy"
          src={imgSrc}
          alt={imgAlt}
          className="w-full aspect-[1.02] max-md:max-w-full hover:scale-105 ease-in-out duration-500"
        />
      </div>
      <h2 className="mt-10 max-md:mt-6 max-md:text-2xl text-4xl font-Merriweather text-footer">
        {title}
      </h2>
      <p className="mt-2.5 text-xl leading-8 text-neutral-600 max-md:text-lg font-Inter">
        {description}
      </p>
    </article>
  );
}

function Services() {
  const services = [
    {
      imgSrc: behandlung,
      title: "Gesichtsbehandlung",
      description:
        "Luxuriöse Gesichtsbehandlungen, die Ihre Haut zum Strahlen bringen und jugendlich frisch wirken lassen.",
      imgAlt: "Face treatment",
    },
    {
      imgSrc: waxing,
      title: "Wachsen",
      description:
        "Verwenden Sie Heißwachs oder Wachsstreifen für glatte, langanhaltende Ergebnisse.",
      imgAlt: "Waxing",
    },
    {
      imgSrc: laser,
      title: "Laser",
      description:
        "Effektive Haarentfernung mit modernster Lasertechnologie für dauerhaft glatte Haut.",
      imgAlt: "Laser treatment",
    },
    {
      imgSrc: wimpern,
      title: "Augenbrauen & Wimpern",
      description:
        "Perfekt geformte Brauen und üppige Wimpern zur Betonung Deiner natürlichen Schönheit.",
      imgAlt: "Eyebrows and lashes",
    },
    {
      imgSrc: nails,
      title: "Manikure & Pedikure",
      description:
        "Professionelle Nagelpflege für schön glänzende Hände und Füße.",
      imgAlt: "Manicure and pedicure",
    },
  ];

  return (
    <section className="flex justify-center items-center px-4 md:px-16 py-28 pt-10 w-full bg-background max-md:px-5 max-md:pt-28 min-h-screen">
      <div className="flex flex-col my-5 w-full max-w-[1260px]">
        <header className="flex flex-col items-center w-full max-md:max-w-full">
          <h1 className="text-4xl md:text-7xl font-normal text-black leading-tight md:leading-[72px] font-Merriweather text-footer">
            Unsere Dienstleistungen
          </h1>
          <p className="mt-6 md:text-2xl leading-8 md:leading-10 text-center text-neutral-600 w-full md:max-w-[1001px] font-Inter">
            Bei Beauty Point Nesrin bieten wir eine Vielzahl hochwertiger
            Kosmetikdienstleistungen an, die auf Ihre Schönheitsbedürfnisse
            zugeschnitten sind. Unsere Behandlungen werden von erfahrenen
            Fachkräften durchgeführt.
          </p>
        </header>

        <section className="mt-10 md:mt-16">
          <div className="flex flex-col md:flex-row gap-5">
            <article className="flex flex-col w-full md:w-6/12 space-y-10">
              <ServiceCard
                imgSrc={services[0].imgSrc}
                title={services[0].title}
                description={services[0].description}
                imgAlt={services[0].imgAlt}
              />
              <ServiceCard
                imgSrc={services[1].imgSrc}
                title={services[1].title}
                description={services[1].description}
                imgAlt={services[1].imgAlt}
              />
            </article>

            <article className="flex flex-col w-full md:w-6/12 space-y-10">
              <ServiceCard
                imgSrc={services[2].imgSrc}
                title={services[2].title}
                description={services[2].description}
                imgAlt={services[2].imgAlt}
              />
              <ServiceCard
                imgSrc={services[3].imgSrc}
                title={services[3].title}
                description={services[3].description}
                imgAlt={services[3].imgAlt}
              />
            </article>
          </div>
        </section>

        <section className="mt-10 md:mt-16">
          <div className="flex flex-col md:flex-row gap-5">
            <article className="flex flex-col w-full md:w-6/12">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/68422c24459d0c1f4fbb4fa0cde8f471494e46d4471e404d4ff73ea9729a46ee?apiKey=f6218d6cfb544189918ec2451f1a7919&"
                alt=""
                className="w-full aspect-[1.45] hidden md:block"
              />
            </article>
            <article className="flex flex-col w-full md:w-6/12 space-y-10">
              <ServiceCard
                imgSrc={services[4].imgSrc}
                title={services[4].title}
                description={services[4].description}
                imgAlt={services[4].imgAlt}
              />
            </article>
          </div>
        </section>
      </div>
    </section>
  );
}

export default Services;
