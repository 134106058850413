import * as React from "react";
import hero_small from "../assets/hero_small.webp";

function CallToAction({ text }) {
  return (
    <a href="https://buchung.treatwell.de/ort/436120/menue/">
      <button
        className="justify-center px-14 py-4 font-medium whitespace-nowrap bg-button rounded-xl leading-[130%] max-md:px-5 transition-colors duration-300 hover:bg-button_hover"
        tabIndex="0"
      >
        {text}
      </button>
    </a>
  );
}

function Hero() {
  return (
    <main className="relative bg-contain bg-center bg-no-repeat h-dvh bg-background overflow-hidden">
      <div className="absolute inset-0 bg-contain bg-bottom bg-no-repeat md:animate-slideUp bg-hero md:bg-right bg-bottom"></div>
      <div className="relative flex flex-col text-lg text-black max-w-[1260px] mx-auto justify-center items-start h-screen max-md:px-5">
        <div className="flex w-full">
          <div className="flex flex-col justify-center w-full md:animate-slideInLeft">
            <header className="w-full text-4xl italic font-normal md:leading-[20px]  text-left max-md:text-2xl max-md:leading-[8px] font-Merriweather text-footer  ">
              Willkommen in deiner
            </header>
            <section className="mt-3 pl-6 text-4xl font-normal leading-[50px] items-center max-md:pl-3 max-md:text-3xl ">
              <h2 className="my-auto text-6xl max-md:text-3xl font-Merriweather text-footer font-black">
                Oase der Schönheit
              </h2>
            </section>
            <section className="flex gap-3 mt-4 w-full max-w-screen-md leading-6 text-neutral-600 items-center text-left max-md:flex-col max-md:gap-4 font-Inter font-normal  ">
              <img
                loading="lazy"
                src={hero_small}
                alt="Kosmetik"
                className="shrink-0 max-w-full aspect-[2.78] w-[150px] h-[60px] max-md:w-[100px] max-md:h-[40px] hidden md:block"
              />
              <p className="max-md:text-center">
                Tauchen Sie ein in eine Welt der Entspannung und des
                Wohlbefindens. Unser erfahrenes Team bietet Ihnen exklusive
                kosmetische Behandlungen, die Ihre natürliche Schönheit
                erstrahlen lassen und Ihr Wohlbefinden steigern.
              </p>
            </section>
            <section className="flex items-start mt-6 max-md:justify-center max-md:w-full font-Inter  ">
              <CallToAction text="Buchen" />
            </section>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Hero;
