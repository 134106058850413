import * as React from "react";
import loc from "../assets/loc.webp";
import loc1 from "../assets/Image.webp";
function About() {
  return (
    <div className="flex justify-center items-center md:min-h-screen py-36">
      <main className="self-center md:mt-44 w-full max-w-[1260px]  max-md:max-w-full">
        <section className="flex gap-5 max-md:flex-col max-md:gap-0 px-5 scroll-mt-16">
          <article className="flex flex-col ml-5 w-[56%] max-md:ml-0 max-md:w-full">
            <header className="flex flex-col grow justify-center items-center md:mt-10 text-black  max-md:max-w-full">
              <h1 className="md:pt-20 self-start text-7xl font-normal leading-[72px]  max-md:max-w-full max-md:text-4xl font-Merriweather text-footer">
                Über uns
              </h1>
              <p className="mt-6 md:text-2xl leading-6 md:leading-10 text-neutral-600 text-center md:text-left max-md:max-w-full font-Inter">
                <span className="text-neutral-600">
                  Unser Studio wurde mit der Leidenschaft für Schönheit und
                  Wellness gegründet. Unser Team aus erfahrenen Fachleuten setzt
                  alles daran, Ihnen höchste Servicequalität in einem luxuriösen
                  Ambiente zu bieten. Für uns bedeutet Schönheit mehr als nur
                  gutes Aussehen – es geht darum, sich selbstbewusst und
                  verwöhnt zu fühlen. Besuchen Sie uns und erleben Sie, wie Sie
                  danach strahlend schön aussehen und sich rundum wohlfühlen.
                </span>
              </p>
              <a href="https://buchung.treatwell.de/ort/436120/menue/">
                <button
                  className="justify-center px-14 py-5 mt-12 text-xl font-Inter font-medium leading-7 bg-button rounded-xl max-md:px-5 max-md:mt-10 transition-colors duration-300 hover:bg-button_hover"
                  tabIndex="0"
                >
                  Jetzt Buchen
                </button>
              </a>
            </header>
          </article>
          <aside className="flex flex-col ml-5 w-[38%] max-md:ml-0 max-md:w-full">
            <img
              loading="lazy"
              src={loc1}
              alt="Luxury Beauty Studio"
              className="grow w-full aspect-[0.71] hidden md:block rounded-full"
            />
          </aside>
        </section>
      </main>
    </div>
  );
}

export default About;
