import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import { FaBars, FaTimes } from "react-icons/fa";
import logo from "../assets/Frame 3.svg";

const navigationItems = [
  { name: "Startseite", link: "startseite" },
  { name: "Warum uns wählen?", link: "warum-wir" },
  { name: "Dienstleistungen", link: "dienstleistungen" },
  { name: "Über uns", link: "über-uns" },
  // { name: "Gesichtsbehandlungen", link: "gesicht" },
];

function DeyniqueAffiliateButton() {
  return (
    <a
      href="https://www.deynique-shop.de/?alias=berlin-akteke"
      target="_blank"
      rel="noopener noreferrer"
    >
      <button className="justify-center px-5 py-4  whitespace-nowrap bg-affiliate_button rounded-xl max-md:px-5 transition-colors duration-300 hover:bg-affiliate_button_hover">
        Exklusive Deynique
        <br />
        Angebote
      </button>
    </a>
  );
}

function Logo() {
  return (
    <div className="flex-shrink-0">
      <Link
        to="startseite"
        smooth={true}
        duration={500}
        className="cursor-pointer"
      >
        <img
          src={logo}
          alt="Logo"
          className=" h-12 md:h-20"
          draggable="false"
        />
      </Link>
    </div>
  );
}

function Navigation({ onLinkClick }) {
  return (
    <nav className="flex flex-col md:flex-row gap-5 md:gap-10 mt-5 md:mt-0 items-center">
      {navigationItems.map((item, index) => (
        <Link
          key={index}
          to={item.link}
          smooth={true}
          duration={500}
          className="navbar-item hover:text-neutral-800"
          onClick={onLinkClick}
        >
          {item.name}
        </Link>
      ))}
    </nav>
  );
}

function ContactButton() {
  return (
    <a href="https://buchung.treatwell.de/ort/436120/menue/">
      <button className="justify-center px-10 py-4 font-medium whitespace-nowrap bg-button rounded-xl max-md:px-5 font-Inter transition-colors duration-300 hover:bg-button_hover">
        Buchen
      </button>
    </a>
  );
}

function Navbar() {
  const [scrollState, setScrollState] = useState({
    isScrolled: false,
    boxShadow: "",
  });
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrollState({ isScrolled: true, boxShadow: "shadow-md" });
    } else {
      setScrollState({ isScrolled: false, boxShadow: "" });
    }
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleLinkClick = () => {
    setIsMobileMenuOpen(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      className={`fixed top-0 left-0 w-full z-50 transition-all duration-300 ${
        scrollState.isScrolled || isMobileMenuOpen
          ? "bg-white"
          : "bg-transparent"
      } ${scrollState.boxShadow}`}
    >
      <div className="flex flex-col md:flex-row gap-6 justify-between items-center w-full max-w-[1260px] mx-auto pt-6 pb-5 md:pb-5 md:pt-6 md:pb-5 max-md:px-5 max-md:max-w-full">
        <div className="flex justify-between w-full md:w-auto">
          <Logo />
          <button
            onClick={toggleMobileMenu}
            className="text-3xl text-neutral-800 md:hidden"
          >
            {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
          </button>
        </div>
        <div
          className={`${
            isMobileMenuOpen ? "max-h-screen" : "max-h-0"
          } overflow-hidden transition-all duration-300 md:max-h-full w-full md:w-auto flex flex-col md:flex-row items-center gap-4 md:mr-9`}
        >
          <Navigation onLinkClick={handleLinkClick} />
          <DeyniqueAffiliateButton />
        </div>
        <div className="hidden md:flex gap-4">
          <ContactButton />
        </div>
      </div>
    </header>
  );
}

export default Navbar;
